import React from 'react';
import { Link } from 'react-router-dom';
import whitelogo from "../assets/images/white-logo.png";

const Footer = () => {
  return (
    < div className='md:mt-28 mt-7 bg-secondary' >
      <div className='container mx-auto pt-20 pb-5'>
        <div className='grid md:grid-cols-3 grid-col-1'>
          <div className='sm:mt-0 mt-7 text-left text-white font-titilliumWeb sm:order-1 order-2'>
            <div className='md:text-3xl text-2xl'>Know more</div>
            <div className='mt-3'>
              <div>
                <Link to="/contact" className="md:text-lg text-md hover:underline">
                  Contact Us
                </Link>
              </div>
              <div>
                <Link to="/demo" className="md:text-lg text-md hover:underline">
                  Check out Our Demo
                </Link>
              </div>
              <div>
                <Link to="/faq" className="md:text-lg text-md hover:underline">
                  FAQ
                </Link>
              </div>
              <div>
                <Link to="" className="md:text-lg text-md hover:underline">
                  Our plugins
                </Link>
              </div>
            </div>
          </div>
          <div className='sm:order-2 order-1'>
            <div className='md:flex md:justify-center'>
            <img src={whitelogo} alt="Logo" />
            </div>
          </div>
          <div className='sm:my-0 my-7 md:text-right text-left text-white font-titilliumWeb sm:order-3 order-3'>
          <div className='md:text-3xl text-2xl'>Contact us</div>
            <div className='mt-3'>
              <div>
                <Link to="" className="md:text-lg text-md hover:underline">
                  +94 770 889 559
                </Link>
              </div>
              <div>
                <Link to="" className="md:text-lg text-md hover:underline">
                  support@eceypay.lk
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className='mt-5 text-sm text-center text-white font-titilliumWeb'>Copyright 2023. All Rights Reserved</div>
      </div>
    </div >
  )
}

export default Footer;
