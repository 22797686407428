import './App.css';
import Header from './Components/Header';
import Footer from './Components/Footer';
import Home from './Pages/Home';
import About from './Pages/About';
import Solutions from './Pages/Solutions';
import Demo from './Pages/Demo';
import Contact from './Pages/Contact/Contact';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

function App() {
  return (
      <Router>
        <Header />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/about' element={<About />} />
          <Route path='/solutions' element={<Solutions />} />
          <Route path='/demo' element={<Demo />} />
          <Route path='/contact' element={<Contact />} />
        </Routes>
        <Footer />
      </Router>
  );
}

export default App;
