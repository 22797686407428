import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logo from "../assets/images/logo.png"

const Header = () => {
    const [isOpen, setIsOpen] = useState(false);
    return (
        <div>
            <div className=" px-6 py-2 flex items-center justify-between flex-wrap shadow-lg">
                <div className="flex items-center flex-shrink-0 text-white">
                    <Link to="/" >
                        <img src={logo} className="mr-2" alt="Logo" />
                    </Link>
                </div>
                <div className="block lg:hidden">
                    <button
                        onClick={() => setIsOpen(!isOpen)}
                        className="px-3 py-2 flex items-center rounded text-black-500 hover:text-black-400"
                    >
                        <svg
                            className={`fill-current h-5 w-5 ${isOpen ? "hidden" : "block"}`}
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
                        </svg>
                        <svg
                            className={`fill-current h-5 w-5 ${isOpen ? "block" : "hidden"}`}
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path d="M10 8.586L2.929 1.515 1.515 2.929 8.586 10l-7.071 7.071 1.414 1.414L10 11.414l7.071 7.071 1.414-1.414L11.414 10l7.071-7.071-1.414-1.414L10 8.586z" />
                        </svg>
                    </button>
                </div>
                <div
                    className={`lg:my-0 my-5 w-full lg:text-center block flex-grow lg:flex lg:items-center lg:w-auto ${isOpen ? "block" : "hidden"}`}
                >
                    <div className="lg:flex-grow sm:text-lg text-md font-titilliumWeb">
                        <Link to="/" className="lg:mt-0 mt-2 mr-9 block lg:inline-block text-dark hover:text-primary focus:text-primary">
                            Home
                        </Link>
                        <Link to="/about" className="lg:mt-0 mt-2 mr-9 block lg:inline-block text-dark hover:text-primary focus:text-primary">
                            About Us
                        </Link>
                        <Link to="/solutions" className="lg:mt-0 mt-2 mr-9 block lg:inline-block text-dark hover:text-primary focus:text-primary">
                            Solutions
                        </Link>
                        <Link to="/demo" className="lg:mt-0 mt-2 mr-9 block lg:inline-block text-dark hover:text-primary focus:text-primary">
                            Demo
                        </Link>
                        <Link to="/contact" className="lg:mt-0 mt-2 mr-9 block lg:inline-block text-dark hover:text-primary focus:text-primary">
                            Contact us
                        </Link>
                    </div>
                    <div className='font-titilliumWeb'>
                        <Link to="/login">
                            <button className="lg:mt-0 mt-4 py-2 px-11 w-32 inline-flex items-center sm:text-lg text-md text-white bg-primary border-primary rounded-full shadow-sm hover:bg-orange hover:shadow-md">
                                Login
                            </button>
                        </Link>
                        <Link to="/register">
                            <button className="lg:mt-0 mt-4 ml-5 py-2 px-8 w-32 inline-flex items-center sm:text-lg text-md text-primary bg-white border-primary border rounded-full shadow-sm hover:bg-orange hover:text-white hover:shadow-md">
                                Register
                            </button>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Header;