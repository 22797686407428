import React from 'react';

const Solutions = () => {
  return (
    <div className='container mx-auto'>
      <div className='md:mt-20 mt-7 lg:text-5xl md:text-4xl text-xl text-center text-primary font-titilliumWeb'>Solutions</div>
      <p className='sm:text-lg text-md mt-4 text-justify text-dark font-titilliumWeb'>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by
        injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum,
        you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the
        Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a
        dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which
        looks reasonable. The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic
        words etc.</p>
      <p className='sm:text-lg text-md mt-4 text-justify text-dark font-titilliumWeb'>Sed ut perspiciatis unde omnis iste natus 
      error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et 
      quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut 
      fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem 
      ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore 
      magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, 
      nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil 
      molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?</p>
    </div>
  )
}

export default Solutions;
