import React from 'react';

const Demo = () => {
  return (
    <div className='container mx-auto'>
      <div className='md:mt-20 mt-7 lg:text-5xl md:text-4xl text-xl text-center text-primary font-titilliumWeb'>Demo</div>
      <p className='sm:text-lg text-md mt-4 text-justify text-dark font-titilliumWeb'>Contrary to popular belief, 
      Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making 
      it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up 
      one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of 
      the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 
      and 1.10.33 of "de Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero, written in 45 BC. 
      This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of 
      Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes from a line in section 1.10.32.</p>
      <p className='sm:text-lg text-md mt-4 text-justify text-dark font-titilliumWeb'>At vero eos et accusamus
       et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores 
       et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia 
       deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita 
       distinctio. Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id 
       quod maxime placeat facere possimus, omnis voluptas assumenda est, omnis dolor repellendus. Temporibus 
       autem quibusdam et aut officiis debitis aut rerum necessitatibus saepe eveniet ut et voluptates repudiandae 
       sint et molestiae non recusandae. Itaque earum rerum hic tenetur a sapiente delectus, ut aut reiciendis 
       voluptatibus maiores alias consequatur aut perferendis doloribus asperiores repellat.</p>
    </div>
  )
}

export default Demo;
