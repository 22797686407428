import React from 'react';
import { Link } from 'react-router-dom';
import company from "../assets/images/company.png"
import visamastercard from "../assets/images/visa-master.png";
import hand from "../assets/images/hand.png";

const Home = () => {

  return (
    <>
      {/* banner */}
      <div className='container mx-auto'>
        <div class="grid md:grid-cols-2 grid-col-1 md:mt-5 mt-7">
          <div className='grid items-center justify-center'>
            <div className='sm:text-left text-center'>
              <div className='lg:text-8xl md:text-5xl text-3xl text-secondary font-bold font-titilliumWeb'>Get your payments </div>
              <div className='lg:text-8xl md:text-5xl text-3xl lg:mt-10 mt-5 text-primary font-bold font-titilliumWeb'>Hassle free!</div>
            </div>
          </div>
          <div>
            <img src={company} className="mr-2" alt="Logo" />
          </div>
        </div>
        <div className='lg:text-3xl md:text-2xl text-xl sm:text-left text-center lg:mt-14 mt-7 text-blue font-titilliumWeb'>Sri Lanka's most productive payment gateway service</div>
      </div>

      <div className='container mx-auto'>
        <div class="grid md:grid-cols-2 grid-col-1 md:mt-28 mt-7 gap-10">
          <div className='grid items-center sm:justify-start justify-center sm:order-first order-last'>
            <img src={visamastercard} className="lg:w-96 md:w-64 w-48" alt="Logo" />
          </div>
          <div className='grid sm:text-left text-center'>
            <hr class="w-24 h-1.5 mb-2 sm:mx-0 mx-auto bg-blue border-0" />
            <div className='lg:text-3xl md:text-2xl text-xl font-semibold text-primary font-titilliumWeb'>Explainer videos that simplify your brand's message.</div>
            <div className='sm:text-lg text-md mt-4 text-dark font-titilliumWeb'>
              <p className='text-justify'>EceyPay is an enhanced online payment solution where the customers can do card payments quickly and securely.</p>
              <p className='text-justify'>As a business, you don't have to worry about long registration procedures or high costs with this solution because EceyPay is a</p>
            </div>
            <Link to="">
              <button className="lg:mt-8 mt-4 lg:py-5 md:py-3 py-2 px-12 sm:text-sm text-sm font-semibold uppercase text-white bg-secondary border-secondary rounded-full shadow-sm font-titilliumWeb hover:bg-blue hover:shadow-md">
                Read more
              </button>
            </Link>
          </div>
        </div>
        <div class="grid md:grid-cols-2 grid-col-1 mt-12 gap-10">
          <div className='grid sm:text-left text-center'>
            <hr class="w-24 h-1.5 mb-2 sm:mx-0 mx-auto bg-blue border-0" />
            <div className='lg:text-3xl md:text-2xl text-xl font-semibold text-primary font-titilliumWeb'>Affordable Packages</div>
            <div className='lg:text-lg md:text-xl text-lg mt-3 text-dark font-semibold font-titilliumWeb'>With no hidden fees or withdrawal intervals</div>
            <div className='sm:text-lg text-md mt-4 text-dark font-titilliumWeb'>
              <p className='text-justify'>EceyPay offers multiple packages at merchants' convenience. These packages are designed to provide the best and affordable online payment service that suits your business. Click below to see full details.</p>
            </div>
            <Link to="">
              <button className="lg:mt-8 mt-4 lg:py-5 md:py-3 py-2 px-12 sm:text-sm text-sm font-semibold uppercase text-white bg-secondary border-secondary rounded-full shadow-sm font-titilliumWeb hover:bg-blue hover:shadow-md">
                View Packages
              </button>
            </Link>
          </div>
          <div className='grid items-center sm:justify-end justify-center'>
            <img src={hand} className="lg:w-74 md:w-64 w-36" alt="Logo" />
          </div>
        </div>
      </div>

      {/* upgrade package */}
      <div className='bg-gradient-to-r from-secondary to-primary md:mt-28 mt-7'>
        <div className='container mx-auto py-24 text-center'>
          <div className='lg:text-3xl md:text-2xl text-xl lg:px-80 leading-10 font-semibold text-white font-titilliumWeb'>You can upgrade your package anytime you want.<br />Start small, Dream big !</div>
          <div class="grid md:grid-cols-3 grid-col-1 mt-20 gap-5">
            <div className='grid bg-white rounded-[3rem]'>
              <div className='py-8 px-10'>
                <div className='lg:text-3xl md:text-2xl text-2xl font-semibold text-primary font-titilliumWeb'>Invoice</div>
                <p className='mt-4 sm:text-base text-sm text-dark font-titilliumWeb'>Our specially develop simple invoice management tool will help you to create and submit your invoices to your clients for payments effortlessly.</p>
                <div className='mt-4 '>
                  <Link to="" className="sm:text-smmd text-md text-warning font-bold font-titilliumWeb hover:text-blue">
                    Read more
                  </Link>
                </div>
              </div>
            </div>
            <div className='grid bg-white rounded-[3rem]'>
              <div className='py-8 px-10'>
                <div className='lg:text-3xl md:text-2xl text-2xl font-semibold text-primary font-titilliumWeb'>Customer Care</div>
                <p className='mt-4 sm:text-base text-sm text-dark font-titilliumWeb'>Allow your customers to track their payments. Your registered customers can view all their payments through a marchant portal customized for you.</p>
                <div className='mt-4 '>
                  <Link to="" className="sm:text-smmd text-md text-warning font-bold font-titilliumWeb hover:text-blue">
                    Read more
                  </Link>
                </div>
              </div>
            </div>
            <div className='grid bg-white rounded-[3rem]'>
              <div className='py-8 px-10'>
                <div className='lg:text-3xl md:text-2xl text-2xl font-semibold text-primary font-titilliumWeb'>Multi Currencies</div>
                <p className='mt-4 sm:text-base text-sm text-dark font-titilliumWeb'>Go global; our online payment gateway solution is capable of receiving payments over 20 currencies including GBP, USD, and EUROS.</p>
                <div className='mt-4 '>
                  <Link to="" className="sm:text-smmd text-md text-warning font-bold font-titilliumWeb hover:text-blue">
                    Read more
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* bring your bussiness up to date in the fast growing industry */}
      <div className='container mx-auto'>
        <div className='md:mt-28 mt-7 lg:text-5xl md:text-4xl text-xl text-center text-primary font-titilliumWeb'>Bring your bussiness up to date in the fast growing industry</div>
        <div class="grid md:grid-cols-2 grid-col-1 md:mt-20 gap-6">
          <div className='grid lg:pr-14 md:pr-8 pr-0 sm:mt-0 mt-5 sm:text-left text-center'>
            <div className='lg:text-3xl md:text-2xl text-xl font-semibold text-blue font-titilliumWeb'>Cashless payment</div>
            <div className='sm:text-md text-md mt-4 text-dark font-titilliumWeb'>
              <p className='text-justify'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
            </div>
          </div>
          <div className='grid lg:pl-20 md:pl-12 pl-0 sm:text-left text-center sm:border-l border-gray-300 border-0'>
            <div className='lg:text-3xl md:text-2xl text-xl font-semibold text-blue font-titilliumWeb'>Multiple online payment options</div>
            <div className='sm:text-md text-md mt-4 text-dark font-titilliumWeb'>
              <p className='text-justify'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
            </div>
          </div>
          <div className='grid lg:pr-14 md:pr-8 pr-0 pt-0 sm:text-left text-center'>
            <div className='sm:pt-5 lg:text-3xl md:text-2xl text-xl font-semibold sm:border-t border-gray-300 text-blue font-titilliumWeb'>Keep track on your receivables</div>
            <div className='sm:text-md text-md mt-4 text-dark font-titilliumWeb'>
              <p className='text-justify'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
            </div>
          </div>
          <div className='grid lg:pl-20 md:pl-12 pl-0 pt-0 sm:text-left text-center sm:border-l border-gray-300 border-0'>
            <div className='sm:pt-5 lg:text-3xl md:text-2xl text-xl font-semibold sm:border-t border-gray-300 text-blue font-titilliumWeb'>User friendly platform</div>
            <div className='sm:text-md text-md mt-4 text-dark font-titilliumWeb'>
              <p className='text-justify'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
            </div>
          </div>
        </div>
      </div>

      {/* How much does it cost */}
      <div className='container mx-auto'>
        <div className='md:mt-28 mt-7 lg:text-5xl md:text-4xl text-xl text-center text-primary font-titilliumWeb'>How much does it cost?</div>
        <div className='md:mt-10 mt-4 lg:text-2xl md:text-xl text-lg text-center text-dark font-titilliumWeb'>There is no registration fee or initial setup fee for the platforms we support.</div>
        <div className='md:mt-4 mt-1 lg:text-2xl md:text-xl text-lg text-center text-dark font-titilliumWeb'>You can check more details on our rates here</div>
      </div>
    </>
  )
}

export default Home;
