import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import ReCAPTCHA from "react-google-recaptcha";
import contactImage from "../../assets/images/contact-image.png"

const Contact = () => {
  const recaptchaRef = React.createRef();
  const [mailResponse, setMailResponse] = useState(); // { success : boolean , message : string }

  return (
    <div className='container mx-auto'>
      <div className='md:mt-20 mt-7 lg:text-5xl md:text-4xl text-xl text-center text-primary font-titilliumWeb'>Contact us</div>

      <Formik
        initialValues={{
          companyName: '',
          businessType: '',
          website: '',
          email: '',
          mobileNo: '',
          fixedlineNo: '',
          termsAgree: false,
          reCaptchaToken: ''
        }}
        validationSchema={Yup.object({
          companyName: Yup.string()
            .max(15, 'Must be 15 characters or less')
            .required('Required'),
          businessType: Yup.string()
            .max(15, 'Must be 15 characters or less')
            .required('Required'),
          website: Yup.string()
            .max(15, 'Must be 15 characters or less')
            .required('Required'),
          email: Yup.string().email()
            .required('Required'),
          mobileNo: Yup.string()
            .max(15, 'Must be 15 characters or less')
            .required('Required'),
          fixedlineNo: Yup.string()
            .max(15, 'Must be 15 characters or less')
            .required('Required'),
          reCaptchaToken: Yup.string()
            .required('Required')
        })}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);
          axios.post('/api/contact.php', values, {
              headers: {
                  'Content-Type': 'application/json',
              }
          }).then((res) => {
            setMailResponse(res.data);
          }).catch(() => {
            setMailResponse({
              success : false,
              message : 'Something went wrong. Please try again.'
            })
          }).finally(() => {
            setSubmitting(false);
            setTimeout(() => {
              setMailResponse(undefined);
            }, 4000);
          });
        }}
      >

        {({
          values,
          isSubmitting,
          setFieldValue
        }) => (
          <Form noValidate className='my-10'>
            {/* <div className='flex flex-row sm:flex-col'>
              <div className='w-full sm:2/5'>
                sadas
              </div>
              <div className='w-full sm:3/5'>
                <img className='w-full' src={contactImage} alt="Contact" />
              </div>
            </div> */}
            <div className='grid grid-cols-5 gap-6'>
              <div className='col-span-5 sm:col-span-2'>
                <div className="sm:col-span-3 mt-3">
                  <label htmlFor="companyName" className="block text-md font-titilliumWeb leading-6 text-dark">Company name</label>
                  <div className="mt-2">
                    <Field
                      type="text"
                      name="companyName"
                      className="block w-full rounded-md border-0 p-1.5 text-dark shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-md sm:leading-6"
                    />
                    <div className='text-xs text-warning'>
                      <ErrorMessage name='companyName'></ErrorMessage>
                    </div>
                  </div>
                </div>
                <div className="sm:col-span-3 mt-3">
                  <label htmlFor="businessType" className="block text-md font-titilliumWeb leading-6 text-dark">Business type</label>
                  <div className="mt-2">
                    <Field
                      type="text"
                      name="businessType"
                      className="block w-full rounded-md border-0 p-1.5 text-dark shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-md sm:leading-6"
                    />
                    <div className='text-xs text-warning'>
                      <ErrorMessage name='businessType'></ErrorMessage>
                    </div>
                  </div>
                </div>
                <div className="sm:col-span-3 mt-3">
                  <label htmlFor="website" className="block text-md font-titilliumWeb leading-6 text-dark">Website</label>
                  <div className="mt-2">
                    <Field
                      type="text"
                      name="website"
                      className="block w-full rounded-md border-0 p-1.5 text-dark shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-md sm:leading-6"
                    />
                    <div className='text-xs text-warning'>
                      <ErrorMessage name='website'></ErrorMessage>
                    </div>
                  </div>
                </div>
                <div className="sm:col-span-3 mt-3">
                  <label htmlFor="email" className="block text-md font-titilliumWeb leading-6 text-dark">Email</label>
                  <div className="mt-2">
                    <Field
                      type="email"
                      name="email"
                      className="block w-full rounded-md border-0 p-1.5 text-dark shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-md sm:leading-6"
                    />
                    <div className='text-xs text-warning'>
                      <ErrorMessage name='email'></ErrorMessage>
                    </div>
                  </div>
                </div>
                <div className="sm:col-span-3 mt-3">
                  <label htmlFor="mobileNo" className="block text-md font-titilliumWeb leading-6 text-dark">Mobile Number</label>
                  <div className="mt-2">
                    <Field
                      type="text"
                      name="mobileNo"
                      className="block w-full rounded-md border-0 p-1.5 text-dark shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-md sm:leading-6"
                    />
                    <div className='text-xs text-warning'>
                      <ErrorMessage name='mobileNo'></ErrorMessage>
                    </div>
                  </div>
                </div>
                <div className="sm:col-span-3 mt-3">
                  <label htmlFor="fixedlineNo" className="block text-md font-titilliumWeb leading-6 text-dark">Fixedline Number</label>
                  <div className="mt-2">
                    <Field
                      type="text"
                      name="fixedlineNo"
                      className="block w-full rounded-md border-0 p-1.5 text-dark shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-md sm:leading-6"
                    />
                    <div className='text-xs text-warning'>
                      <ErrorMessage name='fixedlineNo'></ErrorMessage>
                    </div>
                  </div>
                </div>
                <div className='mt-5'>
                  {<ReCAPTCHA
                    ref={recaptchaRef}
                    sitekey="6LcLAUAmAAAAAIxJG25W8ZVPot5PUdSFMe1SSO0k"
                    onChange={value => {
                      setFieldValue('reCaptchaToken', value);
                    }}
                  />}
                  <div className='text-xs text-warning'>
                    <ErrorMessage name='reCaptchaToken'></ErrorMessage>
                  </div>
                </div>
                <div className="sm:col-span-3 mt-5">
                  <label className="inline-block pl-[0.15rem] hover:cursor-pointer text-dark font-titilliumWeb">
                    <Field type="checkbox" name="termsAgree" className="relative float-left mr-[6px] h-[1.125rem] w-[1.125rem] appearance-none rounded-[0.25rem] border-[0.125rem] border-solid border-neutral-300 outline-none before:pointer-events-none before:absolute before:h-[0.875rem] 
              before:w-[0.875rem] before:scale-0 before:rounded-full before:bg-transparent before:opacity-0 before:shadow-[0px_0px_0px_13px_transparent] before:content-[''] checked:border-amber-500 checked:bg-amber-500 checked:before:opacity-[0.16] checked:after:absolute checked:after:-mt-px 
              checked:after:ml-[0.25rem] checked:after:block checked:after:h-[0.8125rem] checked:after:w-[0.375rem] checked:after:rotate-45 checked:after:border-[0.125rem] checked:after:border-l-0 checked:after:border-t-0 checked:after:border-solid checked:after:border-white 
              checked:after:bg-transparent checked:after:content-[''] hover:cursor-pointer"
                    />
                    I agree to the Terms and Conditions of Eceypay
                  </label>
                </div>
                <div className='sm:col-span-6 mt-5'>
                  <button type="submit" disabled={!values.termsAgree || isSubmitting} className="inline-block w-26 rounded bg-amber-500 px-6 pb-2 pt-2.5 text-lg font-medium uppercase leading-normal text-white font-titilliumWeb hover:bg-primary-600 
                hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]  focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 disabled:bg-gray-300">
                    {isSubmitting ? 'Please wait..' : 'Submit'}
                  </button>
                </div>
                {
                  mailResponse && <div className={ mailResponse.success ? 'bg-green-600 text-white p-3 my-3' : 'bg-red-400 text-white p-3 my-3'}>
                    {mailResponse.message}
                  </div>
                }
                
              </div>
              <div className='col-span-5 sm:col-span-3 flex items-center'>
                <img className='w-full' src={contactImage} alt="Contact" />
              </div>
            </div>

          </Form>
        )}

      </Formik>

    </div>
  )
}

export default Contact;
